@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: SFProDisplay;
  font-weight: 800;
  src: url("https://static.imascono.com/avatarSelector/SF-Pro-Display-Heavy.otf");
}
@font-face {
  font-family: SFProDisplay;
  font-weight: 700;
  src: url("https://static.imascono.com/avatarSelector/SF-Pro-Display-Bold.otf");
}

@font-face {
  font-family: SFProDisplay;
  font-weight: 600;
  src: url("https://static.imascono.com/avatarSelector/SF-Pro-Display-Semibold.otf");
}

@font-face {
  font-family: SFProDisplay;
  font-weight: 400;
  src: url("https://static.imascono.com/avatarSelector/SF-Pro-Display-Regular.otf");
}

body{
  font-family: 'SFProDisplay';
}
